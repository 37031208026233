import React, { useState } from 'react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'
import logo from '../logo.png';

// 1. Get projectId
const projectId = '617b79c502992b745bf154da4e6c1010'

// 2. Set chains
const mainnet = {
    chainId: 168587773,
    name: 'Sepolia Blast',
    currency: 'ETH',
    explorerUrl: 'https://testnet.blastscan.io',
    rpcUrl: 'https://sepolia.blast.io'
}

// 3. Create modal
const metadata = {
    name: 'Byte on Blast',
    description: 'Byte on Blast',
    url: 'https://byteonblast.com', // origin must match your domain & subdomain
    icons: ['https://avatars.mywebsite.com/']
}

createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [mainnet],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    themeVariables: {
        '--w3m-font-family': 'Inter',
        '--w3m-border-radius-master': '1px',
        '--w3m-accent': '#c72227',
        '--w3m-color-mix': '#000',
        '--w3m-color-mix-strength': 20
    }
})


const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="text-white p-4"
            style={{ boxShadow: '0 1px 4px -1px #ffcf3e' }}
        >
            <div className="container mx-auto flex items-center justify-between">
                {/* Logo visible on all screen sizes */}
                <div className="z-50 flex items-center">
                    <img src={logo} style={{ width: 180 }} className="App-logo" alt="Byte Logo" />
                </div>

                {/* Center column for navigation menu, becomes hidden on mobile */}
                <nav className="hidden md:flex flex-1 justify-center space-x-4">
                    <a href="#" className="hover:underline" style={{fontFamily:"PaladinsStraight"}}>Home</a>
                </nav>

                {/* Connect button, aligned to the right */}
                <div className="hidden md:block">
                    <w3m-button />
                </div>

                {/* Menu toggle button for smaller screens */}
                <div className="md:hidden flex items-center">
                    <w3m-button />
                    <button onClick={toggleMenu} className="ml-5 text-white focus:outline-none">
                        {isMenuOpen ? 'X' : '///'}
                    </button>
                </div>
            </div>

            {/* Mobile Menu */}
            {isMenuOpen && (
                <div className="md:hidden mt-4">
                    <a href="#" className="hover:underline" style={{fontFamily:"PaladinsStraight"}}>Home</a>
                    {/*
                    <button className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2">
                        Connect
            </button>
            */}
                </div>
            )}
        </header>
    );
};

export default Header;
